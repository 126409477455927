import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Box, Heading, Paragraph, Button, Input } from "../commonElements"
import TarotController from "./tarotController"
import styled from "styled-components"
import { CardsData } from "./tarotCards"
import TarotFlipCoverImage from "../../images/tarot/Flip-Cover.jpg"
import seedrandom from "seedrandom"
import { updateTarotText } from "../../redux/tarot/actions"
import TarotCardModal from "./cardModal"
import TarotFlipCardModal from "./flipCardModal"

const GuessButton = styled(Button)`
  width: fit-content;
  border: none;
  background-color: ${props => props.backgroundColor};
  font-size: 1.8rem;
  padding: 12px 35px;
`

const GuessBox = styled(Box)`
  & > button:nth-last-child(2) {
    margin-right: 1.5rem;
  }
  @media screen and (max-width: 447px) {
    min-height: 11rem;
    & > button {
      margin-left: 1rem;
      margin-right: 1rem !important;
      margin-bottom: 0.5rem;
      width: 100%;
      max-width: 30rem;
      padding: 12px 12px;
    }
  }
`

const GuessHeading = styled(Heading)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  letter-spacing: -0.5px;
`

const GuessParagraph = styled(Paragraph)`
  overflow: ${props => props.overflow};
  text-overflow: ${props => props.textOverflow};
  white-space: ${props => props.whiteSpace};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  text-align: center;
  margin-right: 0.5rem;
`

const QuestionBox = styled(Box)`
  box-shadow: ${props =>
    props.showBorder
      ? "0px 8px 6px -6px rgb(187 187 187), 0px -8px 6px -6px rgb(187 187 187)"
      : "none"};
  min-height: 3.7rem;
  min-width: 450px;
  @media screen and (max-width: 500px) {
    min-width: 90%;
  }
`

const GuessInput = styled(Input)`
  text-align: center;
  border: none;
  background-color: ${props => props.bgColor};
`

const MainPage = styled(Box)`
  max-height: 570px;
  @media ${props => props.theme.device.tablet} {
    max-height: 100%;
  }
`

const Tarot = () => {
  const questionOnPersist = useSelector(state => state.tarot.question.on)
  const [questionOn, setQuestionOn] = useState(false)
  const textPersist = useSelector(state => state.tarot.text)
  const [text, setText] = useState("")
  const toolColorsPersist = useSelector(state => state.general.colors.array)
  const [toolColors, setToolColors] = useState(["#3D3D3D", "#3D3D3D"])
  const [shuffleClicked, setShuffleClicked] = useState(0)
  const [cardNumber, setCardNumber] = useState(0)
  const [flipOn, setFlipOn] = useState(false)
  const [flipCSS, setFlipCSS] = useState("")
  // const typePersist = useSelector(state => state.tarot.type)
  const cardsData = CardsData.data
  const [loaded, setLoaded] = useState(false)
  const [onFocusQuestion, setOnFocusQuestion] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (toolColorsPersist !== undefined) {
      setToolColors(toolColorsPersist)
    } else {
      setToolColors(["#59569D", "#F25292"])
    }
  }, [toolColorsPersist])

  useEffect(() => {
    if (textPersist !== text) {
      setText(textPersist)
    }
  }, [textPersist])

  useEffect(() => {
    if (questionOnPersist !== questionOn) {
      setQuestionOn(questionOnPersist)
    }
  }, [questionOnPersist])

  // useEffect(() => {
  //   let data = []
  //   if (typePersist === "Yes or No") {
  //     data = CardsData.data.filter(card => card.answer !== "MAYBE")
  //   } else {
  //     data = CardsData.data
  //   }
  //   setCardsData(data)
  // }, [typePersist])

  const putInCache = async (request, response) => {
    const cache = await caches.open("TarotCardV1")
    await cache.put(request, response)
  }

  const cacheFirst = async ({ request, fallbackUrl }) => {
    try {
      // First try to get the resource from the cache
      const responseFromCache = await caches.match(request)
      if (responseFromCache) {
        return responseFromCache
      }

      // Next try to get the resource from the network
      try {
        const responseFromNetwork = await fetch(request)
        if (responseFromNetwork.ok) {
          // response may be used only once
          // we need to save clone to put one copy in cache
          // and serve second one
          putInCache(request, responseFromNetwork.clone())
          return responseFromNetwork
        } else {
          throw "invalid url"
        }
      } catch (error) {
        const fallbackResponse = await caches.match(fallbackUrl)
        if (fallbackResponse) {
          return fallbackResponse
        } else {
          const responseFromNetwork = await fetch(fallbackUrl)
          putInCache(request, responseFromNetwork.clone())
          return responseFromNetwork
        }
      }
    } catch (error) {
      const responseFromNetwork = await fetch(request)
      return responseFromNetwork
    }
  }

  const getImageBlob = async url => {
    const imageResponse = await cacheFirst({
      request: url,
      fallbackUrl:
        "https://firebasestorage.googleapis.com/v0/b/flipsimu-53b2c.appspot.com/o/fs-tarot%2FFlip-Cover.jpg?alt=media&token=308610b1-3240-4f98-bbce-e6c87db10b62",
    })
    return imageResponse.blob()
  }

  const handlePickRandom = () => {
    const min = 1,
      max = getTotalCards()
    const mathRandom = seedrandom()
    const ranNum = Math.floor(mathRandom() * (max - min + 1)) + min
    handleFetchCard(ranNum)
    handleFlipCard(ranNum)
  }

  const getTotalCards = () => {
    // return typePersist === "Yes or No" ? 66 : 78
    return 78
  }

  const handlePick = num => {
    handleFetchCard(num)
    handleFlipCard(num)
  }

  const handleFetchCard = async num => {
    setLoaded(false)
    const card1 = document.getElementById("tarot-card-image")
    const card2 = document.getElementById("tarot-card-image-small")
    const card3 = document.getElementById("tarot-card-image-flip")
    card1.src = TarotFlipCoverImage
    card2.src = TarotFlipCoverImage
    card3.src = TarotFlipCoverImage

    const imageBlob = await getImageBlob(cardsData[num - 1].image)
    const imageSource = window.URL.createObjectURL(imageBlob)
    card1.src = imageSource
    card2.src = imageSource
    card3.src = imageSource
    setLoaded(true)
  }

  const handleFlipCard = num => {
    setFlipOn(true)
    setCardNumber(num)
    let styles = `animation-name: enlargeCss;
    @keyframes enlargeCss { 
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1.5);
      }
    }`
    setFlipCSS(styles)
    setTimeout(() => {
      let styles = `animation-name: flipCss;
      @keyframes flipCss { 
        from {
          transform: rotateY(0deg) scale(1.5);
        }
        to {
          transform: rotateY(180deg) scale(1.5);
        }
      }`
      setFlipCSS(styles)
    }, 700)
    setTimeout(() => {
      setFlipCSS("")
      setFlipOn(false)
    }, 1500)
  }

  return (
    <Box
      alignItems="center"
      width="100%"
      alignSelf="stretch"
      flex="1"
      // justifyContent="center"
      positionBox="relative"
    >
      <TarotCardModal
        cardNumber={cardNumber}
        flipOn={flipOn}
        loaded={loaded}
        setCardNumber={value => setCardNumber(value)}
      />
      <TarotFlipCardModal
        cardNumber={cardNumber}
        flipOn={flipOn}
        flipCSS={flipCSS}
      />

      <MainPage
        alignItems="center"
        padding="3rem 1rem 2rem"
        width="100%"
        alignSelf="stretch"
        flex="1"
        justifyContent="center"
        positionBox="relative"
      >
        <GuessHeading level={1} mb=".8rem">
          YES NO TAROT
        </GuessHeading>
        <Heading
          level={2}
          style={{
            fontSize: "1.6rem",
            fontWeight: "normal",
            margin: "0 .5rem 1rem",
            lineHeight: "1.5",
            textAlign: "center",
          }}
        >
          Get an instant YES or NO Tarot answer for your question now
        </Heading>
        <QuestionBox>
          {!questionOn && (
            <GuessParagraph
              fontSize="2rem"
              mb="0rem"
              ml="0rem"
              minHeight="3.6rem"
            ></GuessParagraph>
          )}
          {questionOn && (
            <GuessInput
              width="100%"
              value={text}
              placeholder="Type something..."
              fontSize="1.4rem"
              padding="6.5px 10px"
              bgColor={text === "" || onFocusQuestion ? "#e2e2e2" : "#F0F0F0"}
              onFocus={() => setOnFocusQuestion(true)}
              onBlur={() => setOnFocusQuestion(false)}
              onChange={event => {
                event.preventDefault()
                const tempText = event.target.value
                setText(tempText)
                dispatch(updateTarotText(tempText))
              }}
            />
          )}
        </QuestionBox>
        <Box
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          positionBox="relative"
          width="100%"
          height="100%"
          mb="auto"
        >
          <TarotController
            shuffleClicked={shuffleClicked}
            cardPicked={value => handlePick(value)}
            cardNumber={cardNumber}
          />
        </Box>
        <GuessBox
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          positionBox="relative"
          width="100%"
          mb="1.5rem"
          minHeight="5rem"
        >
          {cardNumber === 0 && (
            <>
              <GuessButton
                backgroundColor={toolColors[0]}
                onClick={() => {
                  setShuffleClicked(shuffleClicked + 1)
                }}
              >
                SHUFFLE
              </GuessButton>
              <GuessButton
                backgroundColor={toolColors[1]}
                onClick={() => {
                  handlePickRandom()
                }}
              >
                PICK RANDOMLY
              </GuessButton>
            </>
          )}
        </GuessBox>
      </MainPage>
    </Box>
  )
}

export default Tarot
