import React, { useEffect } from "react"
import Layout from "../layout/GeneralLayout"
import { Box, GlassBox } from "../components/commonElements"
import Tarot from "../components/tarot"
// import ToolLinkBoardList from "../components/toolLinkBoard/toolLinkBoardList"
import ContentBoard from "../components/contentBoard"
import Footer from "../components/footer"
import styled from "styled-components"
import SEO from "../components/seo"
import ShareModal from "../components/modal/ShareModal"
import FileModal from "../components/modal/FileModal"
import { useSelector, useDispatch } from "react-redux"
import ToolSettings from "../components/toolSettings"
import Adthrive from "../components/adthrive"
import { useToastContext } from "../components/contextHook/toast"
import { usePageLoadingContext } from "../components/contextHook/pageLoading"
import LogoProfile from "../images/tools/tarot-image-big.png"
import SettingsBoard from "../components/settingsBoard"
import queryString from "query-string"
import { getPersistor } from "../utils/reduxPersistor"
import { getShareLink } from "../redux/shareLink/actions"

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerSize};
  display: grid;
  grid-template-columns: minmax(60.2rem, 100%) 30rem;
  /* ${props =>
    props.hideSettings
      ? `grid-template-columns: minmax(60.2rem, 100%) 5rem 30rem;`
      : `grid-template-columns: minmax(60.2rem, 100%) minmax(30rem, 34rem) 30rem;`} */
  grid-template-rows: minmax(calc(100vh - 12rem), auto);
  grid-auto-rows: auto;
  grid-template-areas: "Tool RightSide"; //"Tool LeftSide RightSide";
  // "LeftSide Content RightSide";
  gap: 1rem 1rem;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem;
  @media (min-width: 2000px) {
    grid-template-rows: minmax(87rem, auto);
  }
  @media ${props => props.theme.device.tablet} {
    // max-width: 90rem;
    grid-template-columns: minmax(60.2rem, 100%) minmax(18rem, 30rem);
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool RightSide"
      "MiddleAd RightSide"
      "LeftSide RightSide";
    // "LeftSide Content";
  }
  @media ${props => props.theme.device.mobile} {
    // max-width: 60.2rem;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool"
      "MiddleAd"
      "LeftSide"
      "RightSide";
    // "Content";
    padding-bottom: 0rem;
  }
`

const RightSiderbar = styled(Box)`
  grid-area: RightSide;
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`

// const SettingsHideBox = styled(GlassBox)`
//   cursor: pointer;
//   margin-bottom: 0rem;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const ToolExpandCollapseBox = styled.div`
//   position: absolute;
//   right: 2rem;
//   bottom: 2rem;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const SettingsShowBox = styled(GlassBox)`
//   ${props => !props.hideSettings && `display: none;`}
//   height: 100%;
//   // @media screen and (max-height: 840px) {
//   //   height: calc(100% - 10rem);
//   // }
//   cursor: pointer;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const LeftSidebar = styled(Box)`
  /* ${props => props.hideSettings && `display: none;`} */
  display: none;
  grid-area: LeftSide;
  grid-gap: 1rem 1rem;
  & > div:nth-last-child(1) {
    margin-bottom: 0px;
  }
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const LeftSideSettingsBox = styled(Box)`
  grid-area: LeftSideSettings;
  grid-gap: 1rem;
  margin: 0rem;
`

const ToolBox = styled(GlassBox)`
  -webkit-tap-highlight-color: transparent;
  grid-area: Tool;
  align-items: center;
  border: none;
  margin-bottom: 0rem;
  // @media screen and (max-height: 840px) {
  //   margin-bottom: 10rem;
  // }
  // @media ${props => props.theme.device.tablet} {
  //   margin-bottom: 0rem;
  // }
`

const MiddleAdBox = styled(Box)`
  grid-area: MiddleAd;
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const ContentBox = styled(Box)`
  font-size: 1.8rem;
  grid-area: Content;
  background-color: white;
  justify-content: center;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${props =>
    `minmax(45rem,${
      props.theme.containerSize.replace("rem", "") - 31
    }rem) 31rem`};
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: auto;
  }
  padding-bottom: 7rem;
  padding-top: 3rem;
`

// const LeftSidebarAd = styled(Adthrive)`
//   /* max-height: 28rem; */
//   user-select: none;
//   & > div {
//     margin: 0;
//   }
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const RightSidebarAd = styled(Adthrive)`
  justify-content: flex-start;
  & > div {
    margin: 0;
  }
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`
// const MobileHeaderAd = styled(Adthrive)`
//   display: none;
//   @media ${props => props.theme.device.tablet} {
//     min-height: 10rem;
//     & > div {
//       margin-bottom: 0px;
//     }
//     display: flex;
//   }
//   @media ${props => props.theme.device.mobile} {
//     min-height: 11rem;
//   }
// `

const MobileMiddleAd = styled(Adthrive)`
  @media ${props => props.theme.device.tablet} {
    min-height: 28rem;
    & > div {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`

// const ContentRightSidebarAd = styled(Adthrive)`
//   @media ${props => props.theme.device.mobile} {
//     display: none;
//   }
// `

const TarotPage = props => {
  const shareModalOpened = useSelector(state => state.modal.shareModalOpened)
  const fileModalData = useSelector(state => state.modal.fileModalData)
  // const [hideSettings, setHideSettings] = useState(false)
  const { setToast } = useToastContext()
  const { setPageLoading } = usePageLoadingContext()
  const dispatch = useDispatch()

  // useEffect(() => {}, [])

  useEffect(() => {
    const search = props.location.search
      ? queryString.parse(props.location.search)
      : {}
    const shareId = search.id
    if (shareId) {
      getPersistor().pause()
      const data = {
        sharePage: "tarot",
        sharePath: shareId,
      }
      setPageLoading(true)
      dispatch(getShareLink(data)).then(result => {
        setPageLoading(false)
        if (result.error) {
          setToast(result.error)
        }
      })
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      document.getElementById("ToolRightSidebarAd").style.maxHeight = `calc(${
        document.getElementById("ToolContainer").offsetHeight +
        document.getElementById("ToolContentBox").offsetHeight
      }px - 1rem)`
    }
    window.addEventListener("resize", handleResize)
    handleResize()
  }, [])

  return (
    <>
      {/* <MobileHeaderAd
      id="MobileHeaderAd"
      alignItems="stretch"
      justifyContent="center"
    /> */}
      <Layout>
        <Container
          id="ToolContainer"
          // hideSettings={hideSettings}
        >
          {shareModalOpened && <ShareModal />}
          {fileModalData && <FileModal />}
          <LeftSidebar
            gap="1rem"
            // hideSettings={hideSettings}
          >
            <LeftSideSettingsBox>
              <ToolSettings page="tarot" initialState={true} />
            </LeftSideSettingsBox>
            {/* <ToolLinkBoardList /> */}
            {/* <SettingsHideBox
              alignItems="center"
              padding=".5rem"
              onClick={() => {
                setHideSettings(true)
              }}
            >
              <Tooltip content="Hide">
                <AngleDoubleRight size={22} />
              </Tooltip>
            </SettingsHideBox> */}
            {/* <LeftSidebarAd
              id="LeftSidebarAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
              flex="1"
            /> */}
          </LeftSidebar>
          {/* <SettingsShowBox
            gap="1rem"
            padding="2rem 1rem"
            alignItems="center"
            justifyContent="space-between"
            hideSettings={hideSettings}
            onClick={() => {
              setHideSettings(false)
            }}
          >
            <Box gap="3rem">
              <Tooltip content="Customization">
                <Settings size={22} />
              </Tooltip>
            </Box>
          </SettingsShowBox> */}
          <ToolBox id="tarot-tool-box" positionBox="relative">
            <SettingsBoard page="tarot" />
            <Tarot />
            {/* <ToolExpandCollapseBox>
              {hideSettings && (
                <Tooltip content="Collapse">
                  <IconButton
                    aria-label="Collapse"
                    onClick={() => {
                      setHideSettings(false)
                    }}
                    icon={<AngleDoubleLeft size={24} />}
                  />
                </Tooltip>
              )}
              {!hideSettings && (
                <Tooltip content="Expand">
                  <IconButton
                    aria-label="Expand"
                    onClick={() => {
                      setHideSettings(true)
                    }}
                    icon={<AngleDoubleRight size={24} />}
                  />
                </Tooltip>
              )}
            </ToolExpandCollapseBox> */}
          </ToolBox>
          <MiddleAdBox>
            <MobileMiddleAd
              id="MobileMiddleAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
            />
          </MiddleAdBox>
          <RightSiderbar gap="1rem">
            <Box
              id="ToolRightSidebarAd"
              alignItems="center"
              maxWidth="30rem"
              width="100%"
              height="100%"
              positionBox="absolute"
              zIndex="99"
            >
              <RightSidebarAd
                id="RightSidebarAd"
                width="100%"
                height="100%"
                alignItems="stretch"
                justifyContent="center"
              />
            </Box>
          </RightSiderbar>
        </Container>
      </Layout>
      <ContentBox id="ToolContentBox" gap="1rem">
        <ContentBoard file="TarotContent" />
        {/* <ContentRightSidebarAd
        id="ContentRightSidebarAd"
        width="100%"
        alignItems="stretch"
        justifyContent="center"
      /> */}
      </ContentBox>
      <Footer />
    </>
  )
}

export default TarotPage

export const Head = () => (
  <SEO
    pathname="/yes-or-no-tarot/"
    toolPage={true}
    titleP="Yes No Tarot - Get Instant Yes or No Tarot Reading (Free)"
    descriptionP="Do you need a quick yes or no answer? This Yes or No Tarot reading will give you free, straightforward yes no advice by randomly flipping up a Tarot card."
    imageP={LogoProfile}
  />
)
